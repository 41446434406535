import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB5x2q6beYpuw-G_seio9zhVCJP8zVCj28",
  authDomain: "get-healthy-226a8.firebaseapp.com",
  projectId: "get-healthy-226a8",
  storageBucket: "get-healthy-226a8.appspot.com",
  messagingSenderId: "588537217571",
  appId: "1:588537217571:web:8018ff7d31f0608ac22796",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
